import React from 'react'

import { Helmet } from 'react-helmet'

import NaviContainer from '../components/navi-container'
import FooterBar from '../components/footer-bar'
import './products.css'
import './about.css'

const Products = (props) => {
  return (
    <>
    <Helmet>
        <title>Products - specgas</title>
        <meta property="og:title" content="Products - specgas" />
      </Helmet>
      <NaviContainer rootClassName="navi-container-root-class-name"></NaviContainer>
    <div className="products-container">
      <div className="products-banner">
        <h1 className="products-text">Industrial Gases</h1>
        <span className="products-text01">
          SUPPLY GAS IN VARIOUS SIZE AND STANDARD OF GAS CYLINDER
        </span>
    </div>
    <div className="about-container11">
        <div className="about-hero" style={{marginTop: "-80px"}}>
          <div className="about-container5">
            <span className="about-text07">
            <br></br>
              <span>- OXYGEN</span>
              <br></br>
              <span>- CARBON DIOXIDE</span>
              <br></br>
              <span>- NITROGEN</span>
              <br></br>
              <span>- ARGON</span>
              <br></br>
              <span>- DISSOLVED ACETYLENE</span>
              <br></br>
              <span>- COMPRESSED AIR</span>
              <br></br>
              <span>- BREATING AIR</span>
              <br></br>
              <span>- AMMONIA</span>
              <br></br>
              <span>- HELIUM</span>
              <br></br>
              <span>- REFRIGERANT GASES</span>
              <br></br>
              <span>- HIGH PURITY GASES</span>
              <br></br>
              <span>- CALIBRATION GASES</span>
              <br></br>
              <span>- OTHER GASES</span>
            </span>
          </div>
          <div>
          <img
            alt="image"
            src="../playground_assets/IMG_4648.jpeg"
            className="product-image1"
          />
          <img
            alt="image"
            src="../playground_assets/bundle-nitrogen.jpeg"
            className="product-image1"
          />
          </div>
          <div>
           <img
            alt="image"
            src="../playground_assets/item-1679.jpeg"
            className="product-image1"
          />
           <img
            alt="image"
            src="../playground_assets/oxygen.jpeg"
            className="product-image1"
          />
          </div>
        </div>
      </div>
      </div>
      <FooterBar rootClassName="footer-bar-root-class-name3"></FooterBar>
    </>
  )
}

export default Products
