import React from 'react'

import PropTypes from 'prop-types'

import NaviBar from './navi-bar'
import './navi-container.css'

const NaviContainer = (props) => {
  return (
    <div
      data-role="Header"
      className={`navi-container-navbar-container ${props.rootClassName} `}
    >
      <NaviBar rootClassName="navi-bar-root-class-name" className=""></NaviBar>
    </div>
  )
}

NaviContainer.defaultProps = {
  rootClassName: '',
}

NaviContainer.propTypes = {
  rootClassName: PropTypes.string,
}

export default NaviContainer
