import React from 'react'

import { Helmet } from 'react-helmet'

import NaviContainer from '../components/navi-container'
import FooterBar from '../components/footer-bar'
import './about.css'

const About = (props) => {
  return (
    <>
    <Helmet>
        <title>About - specgas</title>
        <meta property="og:title" content="About - specgas" />
      </Helmet>
      <NaviContainer rootClassName="navi-container-root-class-name4"></NaviContainer>
      <div style={{flexDirection:"column"}}>
    <div className="about-container">
      <div className="about-container1">
        <h1 className="about-text">ABOUT US</h1>
      </div>
      <div className="about-container11">
        <div className="about-hero">
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1547895749-888a559fc2a7?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDZ8fGluZHVzdHJ5fGVufDB8fHx8MTY3Mzk2OTc4Ng&amp;ixlib=rb-4.0.3&amp;w=900"
            className="about-image"
          />
          <div className="about-container3">
            <span className="about-text01">
              <span>
                SPECGAS (M) SDN. BHD. (806614-P) was formed on February 18,
                2008, under Company Act 1965 as a private limited company, the
                incorporation of this company to Professionals Specialist major
                business in Industry &amp; chemical gases.
              </span>
              <br></br>
              <br></br>
              <span>
                Our company incorporation over 20 years and started operation
                encompasses all aspect of Cylinder Treatment System &amp; Gases
                Refilling and Engineering Instrument. Hence, we have
                capabilities to solve current industry needs. 
              </span>
              <br></br>
            </span>
          </div>
        </div>
      </div>
      <div className="about-container11">
        <div className="about-hero">
          <div className="about-container5">
            <span className="about-text07">
              <span>
                We&apos;re recognized for focus in safety, quality assurance,
                uninterrupted supplies and responsiveness to specific
                requirements in order to fulfill customer&apos;s expectation.
              </span>
              <br></br>
              <br></br>
              <span>
                In order to increase efficiencies and diversification of the
                business, we have included other range of product and services
                which allows in form of operation efficiency in the market.
              </span>
              <br></br>
              <br></br>
              <span>
                Our satisfied customers consisted from diverse industries,
                including Electronic &amp; Semi-Conductors, Research &amp;
                Laboratory, Chemical &amp; Oil Refinery, Fabrication &amp;
                Engineering, Healthcare &amp; Medicine, Food &amp; Beverage,
                Offshore &amp; Ship-building &amp; etc.
              </span>
              <br></br>
            </span>
          </div>
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1574757974346-45bae947d89a?ixid=Mnw5MTMyMXwwfDF8c2VhcmNofDE0Mnx8aW5kdXN0cnl8ZW58MHx8fHwxNjczOTcwNzA0&amp;ixlib=rb-4.0.3&amp;w=900"
            className="about-image1"
          />
        </div>
      </div>
    </div>
    </div>
    <FooterBar rootClassName="footer-bar-root-class-name1"></FooterBar>
    </>
  )
}

export default About
