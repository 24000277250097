import React, {useRef} from 'react'

import { Helmet } from 'react-helmet'
import emailjs from '@emailjs/browser';
import NaviContainer from '../components/navi-container'
import FooterBar from '../components/footer-bar'
import './contact-us.css'

const ContactUs = (props) => {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault(); // prevents the page from reloading when you hit “Send”
 
    emailjs.sendForm('service_hppza0q', 'template_contact_us', form.current, 'ILRxvPjayFFjW8AMM')
      .then((result) => {
        console.log(result);
        if(result.status === 200){
          alert("sent successfully.");
          document.getElementById("create-course-form").reset();
        } else {
          
          alert("Please send again.")
        }
      }, (error) => {
        console.log(error)
          // show the user an error
      });
  }

  return (
    <>
     <Helmet>
        <title>Contact-Us - specgas</title>
        <meta property="og:title" content="Contact-Us - specgas" />
      </Helmet>
      <NaviContainer rootClassName="navi-container-root-class-name2"></NaviContainer>
    <div className="contact-us-container">
      <div className="contact-us-container1"></div>
      <div className="contact-us-pricing">
      <div className="container">
      <div className="contact-us-location-2">
        <div className="container-word">
            <div className="contact-us-adress">
              <svg viewBox="0 0 1024 1024" className="contact-us-icon">
                <path d="M512 490q44 0 75-31t31-75-31-75-75-31-75 31-31 75 31 75 75 31zM512 86q124 0 211 87t87 211q0 62-31 142t-75 150-87 131-73 97l-32 34q-12-14-32-37t-72-92-91-134-71-147-32-144q0-124 87-211t211-87z"></path>
              </svg>
              <span className="Section-Text">
                B-30-01, Casa Green@Bukit Jalil, Jalan Jalil Perwira, Bukit
                Jalil, 58200 Kuala Lumpur, Wilaya Persekutuan Kuala Lumpur
              </span>
            </div>
            <div className="contact-us-email">
              <svg viewBox="0 0 1024 1024" className="contact-us-icon2">
                <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
              </svg>
              <span className="Section-Text">spegassb@specgas.my</span>
            </div>
            <div className="contact-us-phone">
              <svg
                viewBox="0 0 804.5714285714286 1024"
                className="contact-us-icon4"
              >
                <path d="M804.571 708.571c0 20.571-9.143 60.571-17.714 79.429-12 28-44 46.286-69.714 60.571-33.714 18.286-68 29.143-106.286 29.143-53.143 0-101.143-21.714-149.714-39.429-34.857-12.571-68.571-28-100-47.429-97.143-60-214.286-177.143-274.286-274.286-19.429-31.429-34.857-65.143-47.429-100-17.714-48.571-39.429-96.571-39.429-149.714 0-38.286 10.857-72.571 29.143-106.286 14.286-25.714 32.571-57.714 60.571-69.714 18.857-8.571 58.857-17.714 79.429-17.714 4 0 8 0 12 1.714 12 4 24.571 32 30.286 43.429 18.286 32.571 36 65.714 54.857 97.714 9.143 14.857 26.286 33.143 26.286 50.857 0 34.857-103.429 85.714-103.429 116.571 0 15.429 14.286 35.429 22.286 49.143 57.714 104 129.714 176 233.714 233.714 13.714 8 33.714 22.286 49.143 22.286 30.857 0 81.714-103.429 116.571-103.429 17.714 0 36 17.143 50.857 26.286 32 18.857 65.143 36.571 97.714 54.857 11.429 5.714 39.429 18.286 43.429 30.286 1.714 4 1.714 8 1.714 12z"></path>
              </svg>
              <span>+6012-9003136</span>
            </div>
          </div>
          </div>
        <div className="contact-us-location-1">
          <form ref={form} onSubmit={sendEmail} id="create-course-form" className="contact-us-form-container">
            <span className="contact-us-heading">
              <span className="BigCard-Heading contact-us-text3">
                Get in touch
              </span>
              <br></br>
            </span>
            <input
              type="text"
              required="true"
              name="from_name"
              placeholder="Name"
              className="contact-us-name input"
            />
            <input
              type="text"
              required="true"
              name="phone_number"
              placeholder="Phone Number"
              className="contact-us-name input"/>
            <input
              type="text"
              required="true"
              placeholder="E-mail"
              name="email"
              className="contact-us-email1 input"
            />
            <textarea
              placeholder="Your Message"
              name="message"
              className="contact-us-message textarea"></textarea>
            <input
              type="submit" 
              style={{cursor:"pointer"}} 
              className="contact-us-cta-btn Anchor button" onClick={sendEmail}
              value="Send"/>
          </form>
        </div>
        </div>
      </div>
      <div className="contact-us-container3"></div>
    </div>
    <FooterBar rootClassName="footer-bar-root-class-name"></FooterBar>
    </>
  )
}

export default ContactUs
