import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './navi-text.css'

const NaviText = (props) => {
  return (
    <nav className={`navi-text-nav ${props.rootClassName} `}>
      <Link to="/" className="navi-text-navlink">
        {props.Home}
      </Link>
      <Link to="/products" className="navi-text-navlink1">
        {props.Products}
      </Link>
      <Link to="/facilities" className="navi-text-navlink2">
        {props.Facilities}
      </Link>
      <Link to="/about" className="navi-text-navlink3">
        {props.About}
      </Link>
      <Link to="/contact-us" className="navi-text-navlink4">
        {props.Contact}
      </Link>
    </nav>
  )
}

NaviText.defaultProps = {
  About: 'About',
  Facilities: 'Facilities',
  rootClassName: '',
  Contact: 'Contact Us',
  Products: 'Products',
  Home: 'Home',
}

NaviText.propTypes = {
  About: PropTypes.string,
  Facilities: PropTypes.string,
  rootClassName: PropTypes.string,
  Contact: PropTypes.string,
  Products: PropTypes.string,
  Home: PropTypes.string,
}

export default NaviText
