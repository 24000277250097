import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import NaviContainer from '../components/navi-container'
import FooterBar from '../components/footer-bar'
import './home.css'

const Home = (props) => {
  
  const directToProducts = () => {
    window.location.assign("/products");
  }

  const directToServices = () => {
    window.location.assign("/services");
  }

  const directToFacilities = () => {
    window.location.assign("/facilities");
  }

  return (
    <>
    <div className="home-container">
      <Helmet>
        <title>specgas</title>
        <meta property="og:title" content="specgas" />
      </Helmet>
      <div className="home-container1">
        <NaviContainer></NaviContainer>
        <div className="home-container2">
          <div className="home-container3">
            <div className="home-content-container">
              <div className="home-hero-text">
                <h1 className="home-heading">SPECGAS</h1>
                <span className="home-text">Your Trusted Business Partner</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-container4">
        <div id="process" className="home-services">
          <div className="home-heading-container">
            <h1 className="home-text1 Section-Heading">
              More things to explore
            </h1>
          </div>
          <div className="home-cards-container">
              <div className="home-service-card" onClick={directToProducts}>
              <div className= "home-service-picture" style={{backgroundImage: `url('playground_assets/unsplash-image.jpeg')`}}/>
              <h3 className="home-text2">Products</h3>
              <span  className="home-link Anchor">
                Learn more
              </span>
            </div>
            <div className="home-service-card" onClick={directToServices}>
            <div className= "home-service-picture" style={{backgroundImage: `url('playground_assets/service.jpeg')`}}/>
              <h3 className="home-text2 Card-Heading">Services</h3>
              <span  className="home-link Anchor">
                Learn more
              </span>
            </div>
            <div className="home-service-card" onClick={directToFacilities}>
              <div className= "home-service-picture" style={{backgroundImage: `url('facilities/IMG_4663.jpeg')`}}/>
              <h3 className="home-text2 Card-Heading">Facilities</h3>
              <span  className="home-link Anchor">
                Learn more
              </span>
            </div>
          </div>
        </div>
      </div>
        
      </div>
      <FooterBar></FooterBar>
      </>
  )
}

export default Home
