import React from 'react'

import { Helmet } from 'react-helmet'

import NaviContainer from '../components/navi-container'
import FooterBar from '../components/footer-bar'
import './facilities.css'

const Facilities = (props) => {
  return (
    <>
    <Helmet>
        <title>Facilities - specgas</title>
        <meta property="og:title" content="Facilities - specgas" />
      </Helmet>
      <NaviContainer rootClassName="navi-container-root-class-name3"></NaviContainer>
    <div className="facilities-container">
      <div className="facilities-container01">
        <h1 className="facilities-text">Our Facility</h1>
      </div>
      <div className="facilities-container111">
        <span className="">we're investing in our facilities to improve the experience we deliver to  client and our business partners.</span>
      </div>
      <div className="facilities-container02">
        <div className="facilities-gallery">
        <div className="facilities-container03">
            <div className="facilities-container04">
              <h1 className="facilities-text13"></h1>
              <span className="facilities-text14">
                <span></span>
              </span>
            </div>
          </div>
          <div className="facilities-container11">
            <div className="facilities-container12">
              <h1 className="facilities-text01">Private Gas Cylinder Refilling</h1>
              <span className="facilities-text02">
                <span>Oxygen, Carbon Dioxide, Nitrogen, and Argon</span>
              </span>
            </div>
          </div>
          <div className="facilities-container05">
            <div className="facilities-container06">
              <h1 className="facilities-text04"></h1>
              <span className="facilities-text05">
                <span></span>
              </span>
            </div>
          </div>
          <div className="facilities-container07">
            <div className="facilities-container08">
            </div>
          </div>
          <div className="facilities-container09">
            <div className="facilities-container10">
              <h1 className="facilities-text10"></h1>
              <span className="facilities-text11">
                <span></span>
              </span>
            </div>
          </div>
          <div className="facilities-container13">
            <div className="facilities-container14">
              <h1 className="facilities-text16"></h1>
              <span className="facilities-text17">
                <span></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterBar rootClassName="footer-bar-root-class-name4"></FooterBar>
    </>
  )
}

export default Facilities
