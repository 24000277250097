import React from 'react'

import { Helmet } from 'react-helmet'

import NaviContainer from '../components/navi-container'
import FooterBar from '../components/footer-bar'
import './services.css'

const Services = (props) => {
  return (
    <div className="services-container">
      <Helmet>
        <title>Services - specgas</title>
        <meta property="og:title" content="Services - specgas" />
      </Helmet>
      <NaviContainer rootClassName="navi-container-root-class-name1"></NaviContainer>
      <div className="services-features">
        <h1 className="services-text">Our Services</h1>
        <span className="services-text03">
          At SPECGAS, we pride ourselves on offering a diverse range of services to all of our clients, regardless of their size. Our commitment to delivering top-notch, personalized service ensures that we consistently exceed our clients' expectations.
        </span>
        <div className="services-features1">
          <div className="services-container01">
            <div className="services-feature-card">
              <div className="services-container02">
                <svg viewBox="0 0 1024 1024" className="services-icon">
                  <path d="M0 854h1024v170h-1024v-170zM810 490q86 94 86 150 0 34-26 60t-60 26-59-26-25-60q0-24 21-62t41-62zM222 426h410l-206-204zM706 382q20 20 20 46t-20 44l-234 234q-20 20-46 20-24 0-44-20l-236-234q-18-18-18-46 0-26 18-44l220-220-102-102 62-60z"></path>
                </svg>
              </div>
              <h2 className="services-text02">Refilling Gas</h2>
              <span className="services-text01">
                Filling Industrial Gases, Calibration Gases and Other Gases
              </span>
            </div>
            <div className="services-feature-card1">
              <svg viewBox="0 0 1024 1024" className="services-icon02">
                <path d="M234 298q26 0 45-19t19-45-19-45-45-19-45 19-19 45 19 45 45 19zM914 494q24 24 24 60t-24 60l-300 300q-24 24-60 24t-60-24l-384-384q-24-24-24-60v-300q0-34 25-59t59-25h300q36 0 60 24z"></path>
              </svg>
              <h2 className="services-text04">
                Gas Cylinder Outright Sales
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </h2>
              <span className="services-text01">
                One-Off Purchase Of Gas Cylinder
              </span>
            </div>
            <div className="services-feature-card2">
              <svg
                viewBox="0 0 1316.5714285714284 1024"
                className="services-icon04"
              >
                <path d="M109.714 658.286c48 0 48-73.143 0-73.143s-48 73.143 0 73.143zM951.429 625.143c-45.143-58.857-89.143-118.857-140.571-172.571l-71.429 80c-62.857 71.429-175.429 69.714-236.571-3.429-43.429-52.571-43.429-128 1.143-180l101.143-117.714c-35.429-18.286-78.286-12-116.571-12-33.714 0-66.286 13.714-90.286 37.714l-90.286 90.286h-88.571v310.857c25.143 0 48-3.429 68 16l169.714 166.857c34.857 33.714 80 63.429 129.714 63.429 25.714 0 53.143-8.571 71.429-26.857 42.857 14.857 92.571-9.143 105.714-53.143 27.429 2.286 52.571-6.286 72.571-25.143 13.143-12 30.286-36 28.571-54.857 5.143 5.143 17.714 5.714 24.571 5.714 68 0 103.429-71.429 61.714-125.143zM1042.286 658.286h54.857v-292.571h-53.143l-89.714-102.857c-24-27.429-60-43.429-96.571-43.429h-95.429c-32 0-62.857 14.286-83.429 38.286l-119.429 138.857c-21.143 25.143-21.143 60.571-0.571 85.714 32.571 38.857 92.571 39.429 126.286 1.714l110.286-124.571c26.286-29.143 74.286-1.714 62.286 35.429 21.714 25.143 45.143 49.714 66.286 74.857 28.571 35.429 56 72.571 84 108.571 17.714 22.857 30.857 50.286 34.286 80zM1206.857 658.286c48 0 48-73.143 0-73.143s-48 73.143 0 73.143zM1316.571 329.143v365.714c0 20-16.571 36.571-36.571 36.571h-248c-20.571 49.714-65.714 82.857-118.286 90.286-24.571 36-62.286 63.429-104.571 72.571-31.429 40-82.286 64-133.143 60.571-94.286 53.143-200.571 6.857-270.857-62.286l-164-161.143h-204.571c-20 0-36.571-16.571-36.571-36.571v-384c0-20 16.571-36.571 36.571-36.571h240.571c66.286-66.286 112-128 211.429-128h66.857c37.143 0 73.143 11.429 103.429 32 30.286-20.571 66.286-32 103.429-32h95.429c108.571 0 153.714 70.857 219.429 146.286h202.857c20 0 36.571 16.571 36.571 36.571z"></path>
              </svg>
              <h2 className="services-text06">Rental of Gas Cyclinder</h2>
              <span className="services-text01">
                Cylinder on Loan to Customer with Rental Charges
              </span>
            </div>
            <div className="services-feature-card3">
              <div className="services-container03">
                <svg viewBox="0 0 1024 1024" className="services-icon06">
                  <path d="M933.79 610.25c-53.726-93.054-21.416-212.304 72.152-266.488l-100.626-174.292c-28.75 16.854-62.176 26.518-97.846 26.518-107.536 0-194.708-87.746-194.708-195.99h-201.258c0.266 33.41-8.074 67.282-25.958 98.252-53.724 93.056-173.156 124.702-266.862 70.758l-100.624 174.292c28.97 16.472 54.050 40.588 71.886 71.478 53.638 92.908 21.512 211.92-71.708 266.224l100.626 174.292c28.65-16.696 61.916-26.254 97.4-26.254 107.196 0 194.144 87.192 194.7 194.958h201.254c-0.086-33.074 8.272-66.57 25.966-97.218 53.636-92.906 172.776-124.594 266.414-71.012l100.626-174.29c-28.78-16.466-53.692-40.498-71.434-71.228zM512 719.332c-114.508 0-207.336-92.824-207.336-207.334 0-114.508 92.826-207.334 207.336-207.334 114.508 0 207.332 92.826 207.332 207.334-0.002 114.51-92.824 207.334-207.332 207.334z"></path>
                </svg>
              </div>
              <h2 className="services-text08">Rental of Offshore Equipment</h2>
              <span className="services-text01">
                Cylinder rack, Basket, Container
              </span>
            </div>
            <div className="services-feature-card4">
              <div className="services-container04">
                <svg viewBox="0 0 1024 1024" className="services-icon08">
                  <path d="M1024 576l-128-256h-192v-128c0-35.2-28.8-64-64-64h-576c-35.2 0-64 28.8-64 64v512l64 64h81.166c-10.898 18.832-17.166 40.678-17.166 64 0 70.692 57.308 128 128 128s128-57.308 128-128c0-23.322-6.268-45.168-17.166-64h354.334c-10.898 18.832-17.168 40.678-17.168 64 0 70.692 57.308 128 128 128s128-57.308 128-128c0-23.322-6.27-45.168-17.168-64h81.168v-192zM704 576v-192h132.668l96 192h-228.668z"></path>
                </svg>
              </div>
              <h2 className="services-text10">Delivery Service</h2>
              <span className="services-text01">
                <span>
                  Deliver Gas Cylinder to Customer
                  <span
                    dangerouslySetInnerHTML={{
                      __html: ' ',
                    }}
                  />
                </span>
                <br></br>
                <span>(depend on the location)</span>
              </span>
            </div>
            <div className="services-feature-card5">
              <div className="services-container05">
                <svg viewBox="0 0 1024 1024" className="services-icon10">
                  <path d="M1002.934 817.876l-460.552-394.76c21.448-40.298 33.618-86.282 33.618-135.116 0-159.058-128.942-288-288-288-29.094 0-57.172 4.332-83.646 12.354l166.39 166.39c24.89 24.89 24.89 65.62 0 90.51l-101.49 101.49c-24.89 24.89-65.62 24.89-90.51 0l-166.39-166.39c-8.022 26.474-12.354 54.552-12.354 83.646 0 159.058 128.942 288 288 288 48.834 0 94.818-12.17 135.116-33.62l394.76 460.552c22.908 26.724 62.016 28.226 86.904 3.338l101.492-101.492c24.888-24.888 23.386-63.994-3.338-86.902z"></path>
                </svg>
              </div>
              <h2 className="services-text15">Service &amp; Maintenance</h2>
              <span className="services-text01">
                Cylinder Inspection, Pocket Testing, Replacement Head Value,
                Instrumental Installation, etc.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="services-blog">
        <div className="services-container06">
          <div className="services-blog-post-card">
            <img
              alt="image"
              src="/facilities/img_4641.jpeg"
              className="services-image"
            />
            <div className="services-container07">
              <span className="services-text17">service &amp; Maintenance</span>
              <h1 className="services-text18">
                Pressure &amp; Hydrostatic Testing
              </h1>
              <div className="services-container08">
                <span className="services-text19">
                  Hydrostatic or pressure testing is performed after the installation of any pipeline to evaluate its limits, including reliability, maximum capacity, leaks, joint fittings, and pressure. The testing helps to ensure the integrity and functionality of the pipeline before it is put into use.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="services-container09">
          <div className="services-blog-post-card1">
            <img
              alt="image"
              src="/playground_assets/cylinder-inspection.jpg"
              className="services-image1"
            />
            <div className="services-container10">
              <span className="services-text20">SERVICE &amp; MAINTENANCE</span>
              <h1 className="services-text21">Cylinder Inspection</h1>
              <div className="services-container11">
                <span className="services-text22">
                  Inspections are conducted throughout the life cycle of a cylinder to ensure its safety and fitness for use. At the time of manufacture, each cylinder undergoes an initial inspection and test. Additional inspections are performed each time the cylinder is filled and periodic inspections are conducted as necessary. This comprehensive approach provides peace of mind that every cylinder remains in good condition and fit for its intended purpose.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="services-container12"></div>
      <FooterBar rootClassName="footer-bar-root-class-name2"></FooterBar>
    </div>
  )
}

export default Services
